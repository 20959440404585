[data-component="Welcome"] {
  .welcome-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // width: 100%;
    z-index: 50;
    overflow-y: auto; // Permet le défilement
    display: flex;
    // align-items: center;
    justify-content: center;
    padding-top: 4rem;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);

  }

  .welcome-card {
    // min-height: 100vh; // Hauteur minimum de 100vh
    width: 50%;
    background: rgba(255, 255, 255);
    border-radius: 1rem;
    padding: 2rem;
    animation: modalEnter 0.3s ease-out;
    height: fit-content;
  }

    @keyframes modalEnter {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .welcome-header {
    text-align: center;
    margin-bottom: 2rem;
    margin: 0 0 2rem 0;
    line-height: normal;
  }

  .welcome-title {
    font-size: 1.875rem;
    color: #DC2626;
    font-weight: 700;
    margin: 0 0 2rem 0;
  }

  .welcome-subtitle {
    font-size: 1.125rem;
    color: #4B5563;
  }

  .info-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }

  .info-item {
    display: flex;
    gap: 1.5rem;
    align-items: center;

    .info-icon {
      width: 2rem;
      height: 2rem;
      color: #DC2626;
      flex-shrink: 0;
      padding: 24px;
      background-color: #f0f0f0;
      border-radius: 50%;
      overflow: visible;
    }

    p {
      color: #4B5563;
      flex: 1;
    }
  }

  .tip-section {
    background-color: #FEF2F2;
    border: 1px solid #FEE2E2;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-bottom: 2rem;

    p {
      color: #991B1B;
      font-weight: 400;
      font-style: italic;
      margin: 0;
    }
  }

  .welcome-footer {
    display: flex;
    justify-content: center;

    .welcome_button {
      background-color: #DC2626;
      color: white;
      padding: 24px 24px;
      border-radius: 99px;
      border: none;
      cursor: pointer;
      font-size: 16px;
      font-weight: 700;
      transition: 0.3s;
      &:hover {
        background-color: #ac1111;
        color: white;
        transform: translateY(-4px);
      }
    }
  }

  // Media queries pour la responsivité
  @media screen and (max-width: 939px) {
    .welcome-card {
      padding: 1.5rem;
      width: 80%;
    }

    .welcome-title {
      font-size: 1.5rem;
    }

    .welcome-subtitle {
      font-size: 1rem;
    }
  }

    @media screen and (min-width: 1401px) {
    .welcome-card {
      width: 40%;
    }
    }
}
