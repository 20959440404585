[data-component='ConsolePage'] {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  & > div {
    flex-shrink: 0;
  }

  .spacer {
    flex-grow: 1;
  }

  .content-top {
    display: flex;
    align-items: center;
    min-height: 40px;

  }

  .content-main {
    flex-grow: 1;
    flex-shrink: 1 !important;
    display: flex;
    overflow: hidden;

    .avatar-button-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 64px;
    }

    .content-block {
      position: relative;
      display: flex;
      flex-direction: column;
      max-height: 100%;
      width: 100%;
      .content-block-title {
        flex-shrink: 0;
        padding-top: 16px;
        padding-bottom: 4px;
        position: relative;
      }
      .content-block-body {
        color: #6e6e7f;
        position: relative;
        flex-grow: 1;
        padding: 8px 0px;
        padding-top: 4px;
        line-height: 1.2em;
        overflow: auto;
        &.full {
          padding: 0px;
        }
      }
    }

    .content-logs {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      & > div {
        flex-grow: 1;
      }
      & > .content-actions {
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
      }
      & > div.events {
        overflow: hidden;
      }
      .events {
        border-top: 1px solid #e7e7e7;
      }
      .conversation {
        display: flex;
        flex-shrink: 0;
        width: 100%;
        overflow: hidden;
        height: 200px;
        min-height: 0;
        max-height: 200px;
        border-top: 1px solid #e7e7e7;
      }
    }
  }

  .visualization {
    position: fixed;
    bottom: 32px;
    right: 32px;
    display: flex;
    padding: 4px;
    z-index: 10;
    gap: 4px;

    @media screen and (max-width: 639px) {
      right: 50%;
      transform: translateX(50%);
      width: fit-content;
      margin: 0 auto;
      bottom: 16px;
    }

    .visualization-entry {
      position: relative;
      display: flex;
      align-items: center;
      height: 40px;
      width: 100px;
      gap: 4px;

      @media screen and (max-width: 639px) {
        width: 100px; // Légèrement plus petit sur mobile
      }

      &.client {
        color: #0099ff;
      }
      &.server {
        color: #009900;
      }
      canvas {
        width: 100%;
        height: 100%;
        color: currentColor;
      }
    }
  }
}
