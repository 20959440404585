[data-component="Avatar"] {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  .avatar-container {
    width: 340px;
    height: 340px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;

    @media screen and (max-width: 639px) {
      width: 260px;
      height: 260px;
    }


    .avatar-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: 1/1;
    }
  }

  .avatar-name {
    font-size: 28px;
    font-weight: 700;
    line-height: normal;
    color: #fff;
  }
}
