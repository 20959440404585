[data-component="Timer"] {
  position: fixed;
  top: 32px;
  left: 32px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;

  @media screen and (max-width: 639px) {
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: fit-content;
  }


  .timer-title {
    color: #fff;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .countdown {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    gap: 8px;
    font-size: 24px;
    font-weight: 700;
    color: #000;
    background-color: rgba(255, 255, 255);
    padding: 12px 16px;
    border-radius: 16px;
    width: fit-content;

    .timer-icon {
      width: 24px;
      height: 24px;
      margin-bottom: 2px;
    }

    &.warning {
      color: #ff4444;
      // animation: pulse 1s infinite;
    }
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
