// src/components/common/button/Button.scss
.custom-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  border-radius: 50px;
  border: none;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  min-width: 200px;

  .button-content {
    display: flex;
    align-items: center;
    gap: 8px;

    .icon {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
    }
  }

  &.start {
    background-color: #37A71E;
    color: white;

    &:hover:not(:disabled) {
      background-color: #2e881a;
      transform: translateY(-1px);
    }

    &:active:not(:disabled) {
      transform: translateY(0);
    }
  }

  &.stop {
    background-color: #CE3313;
    color: white;

    &:hover:not(:disabled) {
      background-color: #ab280e;
      transform: translateY(-1px);
    }

    &:active:not(:disabled) {
      transform: translateY(0);
    }
  }

  // Nouveaux styles pour l'état disabled
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    transform: none;
    pointer-events: none;

    &.start {
      background-color: #37A71E;
    }

    &.stop {
      background-color: #CE3313;
    }
  }
}
